<template>
  <v-chip-group column show-arrows>
    <!--  status -->
    <v-tooltip :bottom="true" v-bind="$attrs" v-on="$listeners">
      <template v-slot:activator="{ attrs, on }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          :small="smallChip"
          :outlined="outlinedStatus"
          :text-color="statusIconColorClass"
        >
          {{ statusText }}
        </v-chip>
      </template>
      {{ statusTooltip }}
    </v-tooltip>

    <!-- priority-->
    <v-chip
      v-if="visiblePriorityChip"
      :small="smallChip"
      :outlined="outlinedStatus"
      :text-color="priorityColorClass"
    >
      <!--      <v-icon left>-->
      <!--        {{ iconExclamation }}-->
      <!--      </v-icon>-->
      {{ priorityText }}
    </v-chip>

    <!-- Overdue -->
    <v-chip
      v-if="visibleOverdueChip"
      :dark="darkChip"
      :small="smallChip"
      :outlined="outlinedStatus"
      :text-color="dueDateIconClass"
    >
      <!--      <v-icon left>-->
      <!--        {{ iconExclamation }}-->
      <!--      </v-icon>-->
      {{ overDueDateText }}
    </v-chip>

    <!-- due date -->
    <v-chip
      v-if="visibleDueChip"
      :dark="darkChip"
      :small="smallChip"
      :outlined="outlinedStatus"
      :text-color="dueDateIconClass"
      >{{ dueDateText }}</v-chip
    >
  </v-chip-group>
</template>

<script>
// model
import {
  findTaskStateColorClass,
  dueDateColorClass,
  taskState,
  taskPriority,
  priorityColorClass
} from "@/model/workflow/task/taskModel";

// services
import {
  formatDueDate,
  formatOverdueText,
  formatPriority,
  formatStatus,
  taskStatusTooltip
} from "@/services/inbox/taskService";

// design
import { iconExclamation } from "@/design/icon/iconConst";

export default {
  name: "TaskStatus",
  data() {
    return {
      iconExclamation: iconExclamation
    };
  },
  props: {
    /**
     * @type {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, priorityText: string,  recordId: number, recordTypeId: number, recordTypeName: string, projectManager: string, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}}
     */
    task: undefined,

    /**
     * is a small chip
     */
    smallChip: {
      type: Boolean,
      default: true
    },

    /**
     * is dark Chip
     */
    darkChip: {
      type: Boolean,
      default: false
    },

    /**
     * is outlined Status
     */
    outlinedStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * task Status
     * @return {number}
     */
    taskStatus() {
      return this.task?.status ?? -1;
    },

    /**
     * project status Icon Color Class
     * @return {String|string|undefined}
     */
    statusIconColorClass() {
      return findTaskStateColorClass(this.taskStatus);
    },

    /**
     * v status Text
     * @return {string}
     */
    statusText() {
      return formatStatus(this.task);
    },

    /**
     * status Tooltip
     * @return {string}
     */
    statusTooltip() {
      return taskStatusTooltip(this.task);
    },

    /**
     * is project Overdue
     * @return {Boolean|boolean} true if the project is overdue
     */
    isOverdue() {
      return this.task?.isOverdue ?? false;
    },

    /**
     * is due date
     * @return {Boolean|boolean} true if the project is due
     */
    isDue() {
      return this.task?.isDue ?? false;
    },

    /**
     * due Date Icon Class
     * @return {String|string}
     */
    dueDateIconClass() {
      return dueDateColorClass(this.task);
    },

    /**
     * overdue Text
     * @return {String|string} overdue Text
     */
    dueDateText() {
      return this.task?.dueDate ? `Due ${formatDueDate(this.task)}` : "";
    },

    /**
     * Overdue Text
     * @return {string}
     */
    overDueDateText() {
      return formatOverdueText(this.task);
    },

    /**
     * visible Due Chip
     * @return {boolean}
     */
    visibleDueChip() {
      if (this.taskStatus !== taskState.completed) {
        if (this.task?.dueDate) {
          return this.task?.isDue ?? false;
        }
      }
      return false;
    },

    /**
     * visible Overdue Chip
     * @return {boolean}
     */
    visibleOverdueChip() {
      return this.task?.isOverdue ?? false;
    },

    /**
     * visible Overdue Chip
     * @return {boolean}
     */
    visiblePriorityChip() {
      return (this.task?.priority ?? taskPriority.normal) === taskPriority.high;
    },

    /**
     * priority Text
     * @return {string}
     */
    priorityText() {
      return formatPriority(this.task);
    },

    /**
     * priority Color Class
     * @return {string|undefined}
     */
    priorityColorClass() {
      return priorityColorClass(this.task);
    }
  }
};
</script>
